import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AboutUsSection from "./pages/AboutPage";
import AdminPanel from "./pages/AdminPanel";
import PrivateRoute from "./privateroute";
import OurServicesPage from "./pages/OurServicesPage";
import ServiceDetailPage from "./pages/ServicesDetailPage";
import ProjectDetails from "./components/molecules/ProjectDetails";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import ProjectsPage from "./pages/ProjectsPage";
import NotFoundPage from "./pages/NotFoundPage";
import FloatingWhatsApp from "./components/molecules/FloatingWhatsapp";

function RouterPage() {
  const location = useLocation();

  // Admin panel ve diğer belirli sayfaları kontrol etmek için
  const hideFloatingWhatsApp = ["/admin", "/adminpanel"].includes(location.pathname);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<AboutUsSection />} />
        <Route path="/hizmetlerimiz" element={<OurServicesPage />} />
        <Route path="/services" element={<OurServicesPage />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/project" element={<ProjectsPage />} />
        <Route path="/services/:title" element={<ServiceDetailPage />} />
        <Route path="/project/:title" element={<ProjectDetails />} />
        <Route
          path="/adminpanel"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {/* Floating WhatsApp yalnızca belirtilen sayfalarda gösterilmeyecek */}
      {!hideFloatingWhatsApp && <FloatingWhatsApp />}
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <RouterPage />
    </BrowserRouter>
  );
}
