import React from "react";

const FloatingWhatsApp = () => {
  return (
    <a
      href="https://wa.me/905454739309"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        borderRadius: "50%",
        padding: "10px",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        zIndex: "1000",
      }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={{ width: "40px", height: "40px" }}
      />
    </a>
  );
};

export default FloatingWhatsApp;
