import React from 'react'
import './loader.css'

interface LoaderProps {
    count?: number; // Slider sayısını dinamik olarak belirlemek için
  }
  
  const Loader: React.FC<LoaderProps> = ({ count = 5 }) => {
    return (
      <section className="loader">
        {Array.from({ length: count }, (_, i) => (
          <div className="slider" style={{ '--i': i } as React.CSSProperties} key={i}></div>
        ))}
      </section>
    );
  };
  
  export default Loader;