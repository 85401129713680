import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAboutUs } from '../store/feature/aboutusSlice';
import AppBar from '../components/molecules/Appbar';
import { AppDispatch, RootState } from '../store';
import Footer from '../components/molecules/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AboutUsSection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { content, loading, error } = useSelector((state: RootState) => state.aboutus);

  useEffect(() => {
    dispatch(fetchAboutUs());
  }, [dispatch]);

  return (
     <HelmetProvider>
              <Helmet>
              <title>Hakkımızda - İsttek Zemin</title>
      <meta
        name="description"
        content="İsttek Zemin olarak, sondaj, jeofizik ve zemin etüdü alanlarında yıllara dayanan tecrübemizle kaliteli ve güvenilir hizmetler sunuyoruz."
      />
      <meta
        name="keywords"
        content="İsttek Zemin, zemin mühendisliği, sondaj şirketi, jeofizik hizmetleri, zemin etüdü"
      />
      <meta name="author" content="İsttek Zemin" />
      <meta property="og:title" content="Hakkımızda - İsttek Zemin" />
      <meta
        property="og:description"
        content="İsttek Zemin olarak, sondaj, jeofizik ve zemin etüdü alanlarında yıllara dayanan tecrübemizle kaliteli ve güvenilir hizmetler sunuyoruz."
      />
      <meta property="og:url" content="https://www.isttekzemin.com/hakkimizda" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://www.isttekzemin.com/hakkimizda" />
      </Helmet>
    <div style={styles.container}>
      <AppBar />
      <div style={styles.header}>
        <h1 style={styles.title}>Hakkımızda</h1>
        <p style={styles.breadcrumb}>Anasayfa &gt; Hakkımızda</p>
      </div>
      <div style={styles.content}>
        <h2 style={styles.subtitle}>Hakkımızda</h2>
        {loading && <p style={styles.text}>Yükleniyor...</p>}
        {error && <p style={{ ...styles.text, color: 'red' }}>{error}</p>}
        {!loading && !error && (
          <div
            style={styles.text}
            dangerouslySetInnerHTML={{
              __html:
                content || "Henüz bir içerik bulunmuyor. Lütfen daha sonra tekrar kontrol ediniz.",
            }}
          ></div>
        )}
      </div>
      <Footer />
    </div>
    
              </HelmetProvider>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
    overflowX: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    backgroundColor: '#000',
    color: '#fff',
    textAlign: 'center',
    padding: '50px 0',
    width: '100%',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
  },
  breadcrumb: {
    color: '#ccc',
    fontSize: '14px',
  },
  content: {
    backgroundColor: '#fff',
    color: '#000',
    margin: '20px auto',
    maxWidth: '800px',
    padding: '20px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    flexGrow: 1,
  },
  subtitle: {
    fontSize: '28px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  text: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
    textAlign: 'justify',
  },
};

export default AboutUsSection;
