import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store"; // Redux store types
import { savePhone, fetchPhones, deletePhone } from "../../store/feature/phoneSlice";
import "./PhonePage.css"; // Add a CSS file

const PhonePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [value, setValue] = useState("");

  const { phones, loading, error } = useSelector((state: RootState) => state.phoneSlice);

  useEffect(() => {
    dispatch(fetchPhones());
  }, [dispatch]);

  const handleSave = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Token bulunamadı. Lütfen giriş yapın!");
      return;
    }
    if (!value) {
      alert("Lütfen değeri girin!");
      return;
    }
    
    dispatch(savePhone({ token, description: "", value })).then(() => {
      dispatch(fetchPhones());
    });
    setValue("");
  };

  const handleDelete = (id: number) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Token bulunamadı. Lütfen giriş yapın!");
      return;
    }
    dispatch(deletePhone({ token, id })).then(() => {
      dispatch(fetchPhones());
    });
  };

  return (
    <div className="phone-container">
      <h2>Telefon Ekle</h2>
      <div className="form-container">
        <input
          type="text"
          placeholder="Değer"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="input-field"
        />
        <button onClick={handleSave} className="save-button">
          Kaydet
        </button>
      </div>

      <h2>Kayıtlı Telefonlar</h2>
      {loading ? (
        <p>Yükleniyor...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <ul className="phone-list">
          {phones.map((phone) => (
            <li key={phone.id} className="phone-item">
              <span>{phone.value}</span>
              <button onClick={() => handleDelete(phone.id)} className="delete-button">
                Sil
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PhonePage;
