import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../store/feature/projectSlice';
import { AppDispatch, RootState } from '../store';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Footer from '../components/molecules/Footer';
import Appbar from '../components/molecules/Appbar';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Loader from '../components/molecules/loader';

const ProjectsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const projects = useSelector((state: RootState) => state.projects.projects);
  const loading = useSelector((state: RootState) => state.projects.loading);
  const error = useSelector((state: RootState) => state.projects.error);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const formatDescription = (rawDescription: string) => {
    const trimmedDescription = rawDescription
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line !== '')
      .join(' ');

    const truncatedDescription = trimmedDescription.length > 100 
      ? `${trimmedDescription.slice(0, 100)}...` 
      : trimmedDescription;

    return `<p>${truncatedDescription}</p>`;
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Projeler alınırken bir hata oluştu: {error}</div>;
  }

  return (
    <HelmetProvider>
                      <Helmet>
                      <title>Projelerimiz - İsttek Zemin</title>
                      <html lang="tr" />
                      <meta http-equiv="Content-Language" content="tr" />
      <meta
        name="description"
        content="İsttek Zemin olarak tamamladığımız projelerle müşterilerimize en iyi hizmeti sunduk. Detaylı proje portföyümüzü incelemek için sayfamızı ziyaret edin."
      />

      <meta
        name="keywords"
        content="Projelerimiz, tamamlanan projeler, zemin mühendisliği projeleri, sondaj projeleri, jeofizik projeleri"
      />
      <meta name="author" content="İsttek Zemin" />
      <meta property="og:title" content="Projelerimiz - İsttek Zemin" />
      <meta
        property="og:description"
        content="İsttek Zemin olarak tamamladığımız projelerle müşterilerimize en iyi hizmeti sunduk. Detaylı proje portföyümüzü incelemek için sayfamızı ziyaret edin."
      />
      <meta property="og:url" content="https://www.isttekzemin.com/projelerimiz" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://www.isttekzemin.com/projelerimiz" />
      </Helmet>
    <div>
      <Appbar />
      <div style={styles.container}>
        <Typography variant="h4" sx={{ textAlign: 'center', margin: '20px 0' }}>
          Projelerimiz
        </Typography>
        <TableContainer component={Paper} sx={{ margin: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>İşveren</b></TableCell>
                <TableCell><b>Başlık</b></TableCell>
                <TableCell><b>Konum</b></TableCell>
                <TableCell><b>Tarih</b></TableCell>
                <TableCell><b>Açıklama</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>{project.employer}</TableCell>
                  <TableCell>{project.title}</TableCell>
                  <TableCell>{project.location}</TableCell>
                  <TableCell>{project.date}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html: formatDescription(project.description) }} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Footer />
    </div>
                      </HelmetProvider>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
  },
};

export default ProjectsPage;
