import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchservices } from '../store/feature/ourservicesSlice';
import { AppDispatch, RootState } from '../store';
import { Box, Typography, Button } from '@mui/material';
import AppBar from '../components/molecules/Appbar';
import Footer from '../components/molecules/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Loader from '../components/molecules/loader';

const OurServicesPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const services = useSelector((state: RootState) => state.ourservices.services);
  const loading = useSelector((state: RootState) => state.ourservices.loading);
  const error = useSelector((state: RootState) => state.ourservices.error);

  useEffect(() => {
    dispatch(fetchservices());
  }, [dispatch]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Hizmetler alınırken bir hata oluştu: {error}</div>;
  }

  return (
    <HelmetProvider>
                  <Helmet>
                  <title>Hizmetlerimiz - İsttek Zemin</title>
      <meta
        name="description"
        content="İsttek Zemin olarak sunduğumuz profesyonel hizmetler arasında sondaj çalışmaları, jeofizik hizmetleri ve zemin etüdü yer almaktadır. Detaylı bilgi için sayfamızı ziyaret edin."
      />
      <meta
        name="keywords"
        content="Hizmetlerimiz, sondaj çalışmaları, jeofizik hizmetleri, zemin etüdü, zemin mühendisliği"
      />
      <meta name="author" content="İsttek Zemin" />
      <meta property="og:title" content="Hizmetlerimiz - İsttek Zemin" />
      <meta
        property="og:description"
        content="İsttek Zemin olarak sunduğumuz profesyonel hizmetler arasında sondaj çalışmaları, jeofizik hizmetleri ve zemin etüdü yer almaktadır. Detaylı bilgi için sayfamızı ziyaret edin."
      />
      <meta property="og:url" content="https://www.isttekzemin.com/hizmetlerimiz" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://www.isttekzemin.com/hizmetlerimiz" />
      </Helmet>

    <div style={styles.container}>
      <AppBar />
      <div style={styles.header}>
        <h1 style={styles.title}>Hizmetlerimiz</h1>
        <p style={styles.breadcrumb}>Anasayfa &gt; Hizmetlerimiz</p>
      </div>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', // Creates a responsive grid with fixed-width cards
          gap: 8, // Reduced gap to 16px to bring the cards closer
          justifyItems: 'center',
          padding: '20px',
          flexGrow: 1, // This ensures the main content grows to take the remaining space
        }}
      >
        {services.map((service) => (
          <Box
            key={service.id}
            sx={{
              width: '300px', // Fixed width
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              height: '380px', // Fixed height
            }}
          >
            <Box
              sx={{
                height: '200px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${service.photoUrl})`, // photo yerine photoUrl // Project image URL
              }}
            />
            <Box sx={{ padding: '16px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                {service.title}
              </Typography>
              <Link
                to={`/services/${encodeURIComponent(service.title)}`} // title URL için encode edildi
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" color="primary">
                  Detaylar
                </Button>
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
      <Footer />
    </div>
                  </HelmetProvider>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: 'Arial, sans-serif',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures the container takes at least the full viewport height
  },
  header: {
    backgroundColor: '#000',
    color: '#fff',
    textAlign: 'center',
    padding: '50px 0',
    width: '100%',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
  },
  breadcrumb: {
    color: '#ccc',
    fontSize: '14px',
  },
};

export default OurServicesPage;
