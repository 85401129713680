import React, { useEffect, useState } from 'react';
import AppBar from '../components/molecules/Appbar';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import photo5 from '../images/IMG_4781-photoaidcom-darken.jpg';
import photo6 from '../images/IMG_4508-photoaidcom-darken.jpg';
import photo7 from '../images/EB150791-9BA1-415B-96C2-1F816DBFBFB5-photoaidcom-darken.jpg';
import './Home.css';
import CompletedProjects from './Project';
import About from './About';
import Footer from '../components/molecules/Footer';
import CommentSlider from '../components/molecules/CommentSlider';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const slides = [
    { image: photo5, text: t('home.ProfessionalandReliableService') },
    { image: photo6, text: t('home.CustomerSatisfactionOrientedSolutions') },
    { image: photo7, text: t('home.FastandGuaranteedSolutions') },
  ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index: number): void => {
    setCurrentSlideIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>İsttek Zemin</title>
        <meta name="İsttek Zemin" 
        content="İsttek Zemin olarak sondaj çalışmaları, jeofizik hizmetleri ve zemin etüdü konularında profesyonel çözümler sunuyoruz. Güvenilir ve kaliteli hizmet için bizi tercih edin."
        />
        <meta
        name="keywords"
        content="istek zemin, istanbul zemin, tekirdağ zemin, sondaj çalışmaları, jeofizik hizmetleri, zemin etüdü, zemin işleri, İsttek Zemin, zemin mühendisliği, sondaj firması"
      />
      <meta name="author" content="İsttek Zemin" />
      <meta property="og:title" content="İsttek Zemin - Sondaj, Jeofizik ve Zemin Etüdü Hizmetleri" />
      <meta
        property="og:description"
        content="İsttek Zemin olarak sondaj çalışmaları, jeofizik hizmetleri ve zemin etüdü konularında profesyonel çözümler sunuyoruz. Güvenilir ve kaliteli hizmet için bizi tercih edin."
      />
      <meta property="og:url" content="https://www.isttekzemin.com" />
      <meta property="og:type" content="website" />
      </Helmet>
    <div className="home-container">
      <AppBar />
      <div
        className="slide"
        style={{
          backgroundImage: `url(${slides[currentSlideIndex].image})`,
        }}
      >
        <div className="overlay">
          <h1 className="slide-title">{slides[currentSlideIndex].text}</h1>
          <Button
            className="corporate-button"
            variant="dark"
            onClick={() => navigate('/hizmetlerimiz')} // Yönlendirme değiştirildi
          >
            {t('home.review')}
          </Button>
        </div>

        {/* Navigation Buttons */}
        <button className="prev-button" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next-button" onClick={nextSlide}>
          &#10095;
        </button>

        {/* Indicators */}
        <div className="indicators">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentSlideIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
      <About />

      <CompletedProjects />
      <br />
      <br />

      
      <CommentSlider  />
      <br />
      <br />

      
      <Footer />
    </div>

    
    </HelmetProvider>
  );
};

export default Home;
