import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchservices, deleteService } from '../../store/feature/ourservicesSlice';
import { AppDispatch, RootState } from '../../store';
import { Box, Typography, Button } from '@mui/material';
import BASE_URL from '../../constants/Config';

const OurServicesList = () => {
  const dispatch: AppDispatch = useDispatch();
  const services = useSelector((state: RootState) => state.ourservices.services);
// ID -> Image URL eşleşmesi

  useEffect(() => {
    dispatch(fetchservices());
  }, [dispatch]);



  const handleDelete = (id: number) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Lütfen giriş yapın.');
      return;
    }
    dispatch(deleteService({ ourServicesId: id, token })).then(() => {
      dispatch(fetchservices()); // Silme sonrası yenile
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
      }}
    >
      {(Array.isArray(services) ? services : []).map((service) => (
        // eslint-disable-next-line no-sequences
        <Box
          key={service.id}
          sx={{
            width: '300px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: '380px',
          }}
        >
          <Box
            sx={{
              height: '200px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage:`url(${service.photoUrl})`,// Görsel yüklendiyse göster
            }}
          />
          <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              {service.title}
            </Typography>
            <Typography
              sx={{
                color: 'gray',
                marginBottom: '12px',
                height: '80px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                lineHeight: 1.4,
              }}
              dangerouslySetInnerHTML={{ __html: service.description }}
            />
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(service.id)}
            >
              Sil
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OurServicesList;
